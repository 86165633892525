import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import {
    Container,
    Row,
    Col,
    Card,
    CardBody
} from "reactstrap";
// import Tramites from "/src/views/Molda/Tramites.js";
// core components

// sections for this page
// import Politica from "./Informacion/Informacion.js";


function Diagnostico() {
    // const [iconTabs, setIconTabs] = React.useState("1");
    // const [Modal3, setModal3] = React.useState(false);
    // const [tooltipOpen, setTooltipOpen] = useState(false);
    // const toggle = () => setTooltipOpen(!tooltipOpen);

    React.useEffect(() => {
        document.body.classList.add("presentation-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;

        // initialise Rellax for this page
        if (window.innerWidth >= 991) {
            setTimeout(function () {
                new Rellax(".rellax", {
                    center: true,
                });
            }, 5000);
            new Rellax(".rellax-header");
            new Rellax(".rellax-text");
        }

        // we need to add a script for the github buttons
        let script = document.createElement("script");
        script.src = "https://buttons.github.io/buttons.js";
        script.id = "github-buttons-script-id";
        document.body.appendChild(script);

        return function cleanup() {
            document.body.classList.remove("tramites-page");
            document.body.classList.remove("sidebar-collapse");

            // we need to remove the script when we change the page
            script.parentNode.removeChild(script);
        };
    });
    return (
        <>
            <Container className="fluid">
                <Row className="justify-content-center">
                    <Col md="10">
                        <Card>
                            <CardBody>
                                <div className="title">
                                    <h1 className="text-justify title" style={{ color: "#031425" }}>
                                        <br></br>
                                        <br></br>
                                    </h1>
                                    <h3 className="text-justify title" style={{ color: "#031425" }}>
                                        <b>Control Social</b><br></br>
                                    </h3>
                                    
                                </div>
                                <p className="text-justify" style={{ fontSize: "14px", color:"#68696F", fontFamily:"serif" }}>
                                    <b>El control social</b> es el derecho y el deber de los ciudadanos a participar, de manera individual o a 
                                    través de sus organizaciones, redes sociales e instituciones, en la vigilancia de la gestión pública y sus 
                                    resultados de acuerdo con lo establecido en la regulación aplicable y correcta utilización de los recursos y
                                    bienes públicos. El concepto ampliado lo brindan los artículos 60, 61 y 63 de la Ley 1757 de 2015.*
                                    <br></br>
                                    <br></br>
                                    <a href="https://www.funcionpublica.gov.co/inicio?p_p_state=maximized&p_p_mode=view&saveLastPath=false&_com_liferay_login_web_portlet_LoginPortlet_mvcRenderCommandName=%2Flogin%2Flogin&p_p_id=com_liferay_login_web_portlet_LoginPortlet&p_p_lifecycle=0&_com_liferay_login_web_portlet_LoginPortlet_redirect=%2Fdocuments%2F">
                                        <b style={{ color: '#045FB4' }}>
                                        * Departamento Administrativo de la Función Pública (2021). Lineamientos para publicar información en el menú participa sobre participación ciudadana en la gestión pública. Obtenido de: https://www.funcionpublica.gov.co/documents/
                                        </b>
                                    </a>
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Diagnostico;