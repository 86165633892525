import React from "react";

// reactstrap components
import {
  Container,

} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";

function Notario() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <ColorNavbar />
      <div className="main" style={{ marginTop: "8em" }}>
        <div className="section">
          <Container>
            <h3 className="title-uppercase text-center" style={{fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif", marginTop: "4em"}}>
              Biografia del notario
            </h3>
            <br></br>
            <p style={{ textAlign: "center", fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif" }}>
              DR. Fernando León Cortes Niño.
            <p>
            <br></br>
              De nacionalidad colombiana, nacido el 21 de agosto del año 1.956 en el Municipio de
              Vélez, Departamento de Santander. Realizo sus estudios en la Facultad de Derecho de la
              Universidad Autónoma de Bucaramanga, donde posteriormente obtuvo su título como
              Especialista en Derecho Notarial y Registral. Se inició en el ejercicio de su profesión como
              abogado en la Oficina de Registro de Instrumentos Públicos de Bucaramanga, durante 5
              años, ejerciendo los cargos de Abogado Oficina Jurídica y Director Administrativo,
              posteriormente fue Secretario General de Planeación y Jefe de relaciones Industriales en
              la Alcaldía de Bucaramanga. donde se desempeñó por 3 años, seguido a esto durante 3
              años, ocupo el cargo de Secretario General y Asesor Jurídico de La Licorera De
              Santander, iniciar con su ejercicio como Notario Decimo del Circulo de Bucaramanga
              desde el 01 de octubre de año 1.996, cargo al que accedió inicialmente en interinidad y
              posteriormente en propiedad por concurso de Notarios y que durante 24 años ha ejercido
              con eficiencia, profesionalismo, legalidad y sentido de pertenecía por prestar un excelente
              Servicio Publico Notarial.
            </p>
            </p>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Notario;
