import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";

function Protocolo() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <ColorNavbar />
      <div className="main" style={{ marginTop: "10em" }}>
        <div className="section">
          <Container>
          <Row className="justify-content-center">
              <Col lg="12">
                <h1 className="text-center title" style={{ color: "#031425", marginTop: "2em" }}>
                 <br></br>
                </h1>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Card>
                  <CardBody>
                    <Row className="justify-content-center">
                      <Col md="6">
                        <center>
                          <a href={require("assets/pdf/1334.pdf")} style={{ textDecoration: "none !important" }} target="_black">
                            <img
                              alt="magen con referencia circular 1334"
                              width="80px"
                              src={require("assets/img/pdf.png")}
                            ></img>
                            <h6>Circular 1334 de 2019</h6>
                          </a>
                        </center>
                      </Col>
                      <Col md="6">
                        <center>
                          <a href="https://www.ucnc.com.co/sitio/wp-content/uploads/2020/09/2020-08-21-PROTOCOLO-DE-BIOSEGURIDAD-PARA-NOTARIAS-UCNC-Version-Final-Anexo-SNR.pdf" style={{ textDecoration: "none !important" }} target="_black">
                            <h6>Protocolo de Bioseguirdad</h6>
                          </a>
                        </center>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>     
          </Container>
        </div>
      </div>
    </>
  );
}

export default Protocolo;
