import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Card, Container, Row, Col, CardBody, CardFooter } from "reactstrap";
import Imagen1 from "assets/img/presentation-page/examples/Oficina13.jpg";
import Imagen2 from "assets/img/presentation-page/examples/Oficina12.jpg";

function SectionExamples() {
  return (
    <>
      <div id="oficinas" className="section section-examples section-dark"  style={{paddingTop:'48px'}}>
        <Container>
          <Row>
            <div className="title text-center">
              <h2 className="title">Nuestras Oficinas</h2>
              <h6 className=" pb-5 text-center">Tenemos un ambiente adecuado para que usted realice sus trámites.</h6>
            </div>
          </Row>
          <Row>
            <Col md="4">
              <Card className="card-image card-hover-effect">
                <Link to="/twitter-redesign" target="_blank">
                  <img
                    alt="Hombre sentado en un escritorio mirando hacia la camara en su oficina"
                    src={Imagen1}
                  />
                </Link>
                <CardBody>
                  <CardFooter>
                    <p className="text-white text-center pt-2">Dr. FERNANDO CORTES NIÑO, Notario Décimo.</p>
                  </CardFooter>
                </CardBody>
              </Card>
              <Card className="card-image card-hover-effect">
                <Link to="/twitter-redesign" target="_blank">
                  <img
                    alt="Un hombre sentando en un mueble de una grande oficina"
                    src={Imagen2}
                  />
                </Link>
                <CardBody>
                  <CardFooter>
                    <p className="text-white text-center pt-2">Despacho notaríal.</p>
                  </CardFooter>
                </CardBody>
              </Card>
              <Card className="card-image card-hover-effect">
                <Link to="/twitter-redesign" target="_blank">
                  <img
                    alt="Cuatro personas sentadas en una mesa para reuniones en una oficina"
                    src={require("assets/img/presentation-page/examples/office5.jpg")}
                  />
                </Link>
                <CardBody>
                  <CardFooter>
                    <p className="text-white text-center pt-2">Sala de Conciliaciones.</p>
                  </CardFooter>
                </CardBody>
              </Card>
              <Card className="card-image">
                <Link to="/register-page" target="_blank">
                  <img
                    alt="Una mujer sentada en un escritorio de oficina"
                    src={require("assets/img/presentation-page/examples/office10.jpg")}
                  />
                </Link>
                <CardBody>
                  <CardFooter>
                    <p className="text-white text-center pt-2">Oficina jefe jurídca, Dra. CAROLINA VARGAS</p>
                  </CardFooter>
                </CardBody>
              </Card>
              <Card className="card-image card-hover-effect">
                <Link to="/twitter-redesign" target="_blank">
                  <img
                    alt="Dos mujeres sentadas en muebles diferentes en la sala de espera de una oficina"
                    src={require("assets/img/presentation-page/examples/office1.jpg")}
                  />
                </Link>
                <CardBody>
                  <CardFooter>
                    <p className="text-white text-center pt-2">Sala de espera de lectura de escrituras</p>
                  </CardFooter>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-image">
                <Link to="/profile-page" target="_blank">
                  <img
                    alt="cinco personas atendiendo en la recepción de la notaría"
                    src={require("assets/img/presentation-page/examples/office9.jpg")}
                  />
                </Link>
                <CardBody>
                  <CardFooter>
                    <p className="text-white text-center pt-2">Autenticaciones</p>
                  </CardFooter>
                </CardBody>
              </Card>
              <Card className="card-image">
                <Link to="/register-page" target="_blank">
                  <img
                    alt="Recepción más pequeña de la notaría 10 de Bucaramanga"
                    src={require("assets/img/presentation-page/examples/office2.jpg")}
                  />
                </Link>
                <CardBody>
                  <CardFooter>
                    <p className="text-white text-center pt-2">Sala de recepción de escrituras</p>
                  </CardFooter>
                </CardBody>
              </Card>
              <Card className="card-image">
                <Link to="/blog-posts" target="_blank">
                  <img
                    alt="Una mujer sentada en una oficina para atender reuniones"
                    src={require("assets/img/presentation-page/examples/office8.jpg")}
                  />
                </Link>
                <CardBody>
                  <CardFooter>
                    <p className="text-white text-center pt-2">Oficina de jurídica, Dra. Carolina Breton</p>
                  </CardFooter>
                </CardBody>
              </Card>
              <Card className="card-image">
                <Link to="/error-500" target="_blank">
                  <img
                    alt="Varias personas posando a la camara en un pasillo de la notaría 10 de Bucaramanga"
                    src={require("assets/img/presentation-page/examples/office3.jpg")}
                  />
                </Link>
                <CardBody>
                  <CardFooter>
                    <p className="text-white text-center pt-2">Sala de autenticaciones con digiturno</p>
                  </CardFooter>
                </CardBody>
              </Card>
              <Card className="card-image">
                <Link to="/product-page" target="_blank">
                  <img
                    alt="Oficinas de la notaría 10 de Bucaramanga"
                    src={require("assets/img/presentation-page/examples/office11.jpg")}
                  />
                </Link>
                <CardBody>
                  <CardFooter>
                    <p className="text-white text-center pt-2">Salón de lectura de escrituras</p>
                  </CardFooter>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">                          
              <Card className="card-image">
                <Link to="/register-page" target="_blank">
                  <img
                    alt="Un hombre sentado en un escritorio de una oficina de la notaría 10 de Bucaramanga"
                    src={require("assets/img/presentation-page/examples/office14.jpg")}
                  />
                </Link>
                <CardBody>
                  <CardFooter>
                    <p className="text-white text-center pt-2">Ing Gil Maria Pinzon TESORERO</p>
                  </CardFooter>
                </CardBody>
              </Card>
              <Card className="card-image">
                <Link to="/register-page" target="_blank">
                  <img
                    alt="Una mujer atendiendo la recepción de la notaría 10 de Bucaramanga"
                    src={require("assets/img/presentation-page/examples/office15.jpg")}
                  />
                </Link>
                <CardBody>
                  <CardFooter>
                    <p className="text-white text-center pt-2">Asistente del notario y contabilidad.</p>
                  </CardFooter>
                </CardBody>
              </Card>
              <Card className="card-image">
                <Link to="/register-page" target="_blank">
                  <img
                    alt="Una mujer sentada en una oficina de la notaría 10 de Bucaramanga"
                    src={require("assets/img/presentation-page/examples/office16.jpg")}
                  />
                </Link>
                <CardBody>
                  <CardFooter>
                    <p className="text-white text-center pt-2">Registro civil y declaraciones extrajuicio</p>
                  </CardFooter>
                </CardBody>
              </Card>
              <Card className="card-image">
                <Link to="/landing-page" target="_blank">
                  <img
                    alt="Parqueadero de la notaría 10 de Bucaramanga"
                    src={require("assets/img/presentation-page/examples/10bga3.png")}
                  />
                </Link>
                <CardBody>
                  <CardFooter>
                    <p className="text-white text-center pt-2">Nuevo parqueadero exclusivo para usuarios, de la Notaría Décima.</p>
                  </CardFooter>
                </CardBody>
              </Card>
              <Card className="card-image">
                <Link to="/product-page" target="_blank">
                  <img
                    alt="Una mujer en la sotea de la notaría 10 de Bucaramanga"
                    src={require("assets/img/presentation-page/examples/office4.jpg")}
                  />
                </Link>
                <CardBody>
                  <CardFooter>
                    <p className="text-white text-center pt-2">Salón de eventos</p>
                  </CardFooter>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionExamples;
