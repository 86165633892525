import React from "react";

// reactstrap components
import {

  Container

} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";


function RetencionDocumental() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <ColorNavbar />
      <div className="main" style={{ marginTop: "10em" }}>
        <div className="section">
          <Container>
            <center className="mt-5">
              <a href={require("assets/pdf/TABLAS DE RETENCION DOCUMENTAL NOTARIA.pdf")} style={{ textDecoration: "none !important" }} target="_blank" rel="noopener noreferrer">
                <img
                  alt="Imagen con referencia hacia un icono para descargar un archivo en PDF"
                  width="80px"
                  src={require("assets/img/sections/pdf.jpg")}
                ></img>
                <h6 className="my-3">Tabla de Retencion Documental</h6>
              </a>
            </center>
            <center>
              <a
                href="http://datos.gov.co/"
                target="_black"
                style={{
                  fontWeight: 500,
                  fontFamily: "'Noto Sans SC', sans-serif",
                  cursor: 'pointer'
                }}
              >
                Enlace a Datos Abiertos
              </a>
            </center>
          </Container>
        </div>
      </div>
    </>
  );
}

export default RetencionDocumental;
