import React, { Fragment } from "react";
import { useSelector,useDispatch } from "react-redux";
import Axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {cargarNotaria} from '../../actions/notariaAction'
import Select from "react-select";

import * as jsPDF from "jspdf";

import logo from "../../assets/img/LOGO_NOT_10.png";

// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Button,
  CardFooter,
  Input,
  InputGroupText,
  InputGroup,
  Table,
  Form
} from "reactstrap";

// core components

const selectOptions = [
  { value: "", label: " Elija tipo de acto", isDisabled: true },
  { value: "1", label: "Compra Venta" },
  { value: "2", label: "Compra Venta e hipoteca con particulares" },
  { value: "3", label: "Compra Venta e Hipoteca con entidades financieras" },
  { value: "4", label: "Hipoteca" },
  { value: "5", label: "Cancelación de hipoteca con particulares" },
  { value: "6", label: "Cancelación de hipoteca con entidades financieras" },
  { value: "7", label: "Matrimonio Civil en la notaría" },
  { value: "8", label: "Matrimonio Civil a domicilio" },
  { value: "9", label: "Sucesiones" },
  { value: "10", label: "Liquidación de sociedad conyugal" }
];

const Cotizador = () => {

  const dispatch = useDispatch()

  const [tipoEscritura, setTipoEscritura] = React.useState("");
  const [nombreActo, setNomreActo] = React.useState("");
  const [valorCuantia, setValorCuantia] = React.useState("");
  const [valorHipoteca, setValorHipoteca] = React.useState("");
  const [tDerechos, setTotalDerechos] = React.useState(0);
  const [tCotizacion, setCotizacion] = React.useState(0);
  const [tFondo, setFondo] = React.useState(0);
  const [tSuper, setSuper] = React.useState(0);
  const [tRete, setRete] = React.useState(0);
  const [tIva, setIva] = React.useState(0);
  const [showHipoteca, setHipoteca] = React.useState(false);
  const [showCuantía, setCuantia] = React.useState(false);
  const [showDerechos, setDerechos] = React.useState(false);
  const [showStatico, setStatico] = React.useState(false);
  const [servicios, setServicios] = React.useState([]);
  const [checkedRete, setCheckedRete] = React.useState(true);
  const [service_disabled, setServiceDisabled] = React.useState([
    1
  ]);
  const notaria = useSelector(state => state.notaria.notaria);
  React.useEffect(() => {
    
    const consultar = async () =>{
      let config = {
        method: 'GET',
        mode: 'cors',
        headers: {"Access-Control-Allow-Origin": true,Authorization: "Bearer "+ process.env.REACT_APP_TOKEN_API},
        
      }

      const resultado = await Axios.get(process.env.REACT_APP_UrlApi+'notaria',config)
      if(resultado.status === 200){
        let dataTemp ={}        
        resultado.data.forEach((element)=>{
          dataTemp[element.variable]=element.value
        })
        dispatch(cargarNotaria(dataTemp))
      }
   }
   consultar()
  },[]);

  React.useEffect(() => {
    const consultarServicios = async () => {
      let config = {
        method: 'GET',
        mode: 'cors',
        headers: {"Access-Control-Allow-Origin": true,Authorization: "Bearer "+ process.env.REACT_APP_TOKEN_API},
        
      }
      const resultado = await Axios.get(
        process.env.REACT_APP_UrlApi + "configCotizador",config
      );

      const options = resultado.data;
      
      setServicios(options);
    };
    consultarServicios();
  }, []);

  const updateValues = (id, value) => {
    let tempService = servicios;
    servicios.forEach((item, index) => {
      if (item.id.toString() === id.toString()) {
        tempService[index].valor_unit = value;
      }
    });
    setServicios(tempService);
    updateTable();
  };

  const updateCantidad = (id, value) => {
    let tempService = servicios;
    servicios.forEach((item, index) => {     
      if (item.id.toString() === id.toString()) {
        tempService[index].cant_predet = value;
      }
    });
    setServicios(tempService);
    updateTable();
  };

  const updateTable = () => {
    var totalCotizacion = 0;

    servicios.forEach((item) => {
      totalCotizacion += item.valor_unit * item.cant_predet;
    });

    let derechosConfig = JSON.parse(notaria.derechos_notariales)
    let fondo = 0;
    fondo =
    valorCuantia <= 100000000
      ? derechosConfig.valorCuantiaMenor100M
      : valorCuantia <= 300000000
      ? derechosConfig.valorCuantiaMenor300M
      : valorCuantia <= 500000000
      ? derechosConfig.valorCuantiaMenor500M
      : valorCuantia <= 1000000000
      ? derechosConfig.valorCuantiaMenor1000M
      : valorCuantia <= 1500000000
      ? derechosConfig.valorCuantiaMenor1500M
      : derechosConfig.valorCuantiaMayor150M;

    setFondo(fondo);
    setSuper(fondo);
    setIva(Math.round(totalCotizacion * derechosConfig.iva/100));
    setRete(Math.round(valorCuantia * derechosConfig.rete/100));
    setCotizacion(
      totalCotizacion +
        fondo +
        fondo +
        Math.round(valorCuantia * derechosConfig.rete/100) +
        Math.round(totalCotizacion *derechosConfig.iva/100)
    );
  };

  const selectTipoEscritura = event => {
    setTipoEscritura(event.value);
    switch (event.value) {
      case "1": //compraventa
        setHipoteca(false);
        setCuantia(true);
        break;
      case "2": // //compraventa e hipoteca con particular
        setHipoteca(true);
        setCuantia(true);
        break;
      case "3": //compraventa e hipoteca con entidad financiera
        setHipoteca(true);
        setCuantia(true);
        break;
      case "4": //hipotecas
        setHipoteca(true);
        setCuantia(false);
        break;
      case "5": //Cancelación hipoteca con particular
        setHipoteca(true);
        setCuantia(false);
        break;
      case "6": //cancelación hipoteca con entidad financiera
        setHipoteca(false);
        setCuantia(false);
        setTotalDerechos(59400);
        break;
      case "7": //Matrimonio Civil
        setHipoteca(false);
        setCuantia(false);
        setTotalDerechos(50000);
        break;
      case "8": //Matrimonio a domicilio
        setHipoteca(false);
        setCuantia(false);
        setTotalDerechos(250000);
        break;
      case "9": //Sucesiones
        setHipoteca(false);
        setCuantia(true);
        break;
      case "10": //Liquidación de sociedad conyugal
        setHipoteca(false);
        setCuantia(true);
        break;
      default:  
    }

    setDerechos(true);
  };

  const CheckedRetencion = val => {
    var totalCotizacion = 0;

    servicios.forEach(item => {
      totalCotizacion += item.valor_unit * item.cant_predet;
    });

    setCheckedRete(val);
    if (val) {
      setCotizacion(totalCotizacion + tFondo + tSuper + tRete + tIva);
    } else {
      setCotizacion(totalCotizacion + tFondo + tSuper + tIva);
    }
  };

  const cotizar = () => {
    let derechosConfig = JSON.parse(notaria.derechos_notariales)
    let tope = derechosConfig.tope;
    let topeSucesiones = derechosConfig.topeSucesiones
    let subtotal = 0;
    let total = 0;
    let base = derechosConfig.base;
    let baseActosSinCuantia = derechosConfig.baseActosSinCuantia;
    let incremento = derechosConfig.incremento/100;
    switch (tipoEscritura) {
      case "1": //compraventa
        if (valorCuantia > tope) {
          subtotal = valorCuantia - tope;
          total = subtotal * incremento + base;
        } else {
          total = base;
        }
        setNomreActo("Compraventa");
        break;
      case "2": // //compraventa e hipoteca con particular
        if (valorCuantia > tope) {
          subtotal = (valorCuantia - tope) * incremento + base;
        } else {
          subtotal = base;
        }
        if (valorHipoteca > tope) {
          subtotal = (valorHipoteca - tope) * incremento + base + subtotal;
        } else {
          subtotal = subtotal + base;
        }
        total = subtotal;
        setNomreActo("Compraventa e hipoteca con particular");
        break;
      case "3": //compraventa e hipoteca con entidad financiera
        if (valorCuantia > tope) {
          subtotal = (valorCuantia - tope) * incremento + base;
        } else {
          subtotal = base;
        }

        if (valorHipoteca > tope) {
          subtotal =
            ((valorHipoteca - tope) * incremento + base) * 0.7 + subtotal;
        } else {
          subtotal = subtotal + base * 0.7;
        }
        total = subtotal;
        setNomreActo("Compraventa e hipoteca con entidad financiera");
        break;
      case "4": //hipotecas
        if (valorHipoteca + valorCuantia > tope) {
          subtotal = valorCuantia + valorHipoteca - tope;
          total = subtotal * incremento + base;
        } else {
          total = base;
        }
        setNomreActo("Hipoteca");
        break;
      case "5": //Cancelación hipoteca con particular
        if (valorHipoteca > tope) {
          subtotal = valorHipoteca - tope;
          total = subtotal * incremento + base;
        } else {
          total = base;
        }
        setNomreActo("Cancelación hipoteca con particular");
        break;
      case "6": //cancelación hipoteca con entidad financiera
        total = derechosConfig.cancelacion_hipoteca;
        setNomreActo("Cancelación hipoteca con entidad financiera");
        break;
      case "7": //Matrimonio Civil
        total = derechosConfig.matrimonio_civil;
        setNomreActo("Matrimonio Civil");
        break;
      case "8": //Matrimonio a domicilio
        total = derechosConfig.matrimonio_domicilio;
        setNomreActo("Matrimonio a domicilio");
        break;
      case "9": //Sucesiones
        if (valorCuantia > topeSucesiones) {
          subtotal = valorCuantia - topeSucesiones;
          total = subtotal * 0.0035 + baseActosSinCuantia;
        } else {
          total = baseActosSinCuantia;
        }
        setNomreActo("Sucesiones");
        break;
      case "10": //Liquidación de sociedad conyugal
        if (valorCuantia > topeSucesiones) {
          subtotal = valorCuantia - topeSucesiones;
          total = subtotal * 0.0035 + baseActosSinCuantia;
        } else {
          total = baseActosSinCuantia;
        }
        setNomreActo("Liquidación de sociedad conyugal");
        break;
      default:
        total = 0;
        break;
    }

    let tipoEscrituraEspecial = ["6", "7", "8"];
    total = Math.round(total);

    if (tipoEscrituraEspecial.indexOf(tipoEscritura) !== -1) {
      setStatico(false);
      return;
    }
    setStatico(true);
    setTotalDerechos(total);
    updateValues(1, total);
    updateTable();
  };

  const formatear = num => {
    while (num.toString().indexOf(".") !== -1) {
      num = num.toString().replace(".", "");
    }
    num = num.toString();
    let resultado = "";
    for (var j, i = num.length - 1, j = 0; i >= 0; i--, j++)
      resultado = num.charAt(i) + (j > 0 && j % 3 === 0 ? "." : "") + resultado;

    return resultado;
  };

  const getDataUri = url => {
    return new Promise(resolve => {
      var image = new Image();

      image.setAttribute("crossOrigin", "anonymous"); //getting images from external domain

      image.onload = function() {
        var canvas = document.createElement("canvas");
        canvas.width = this.naturalWidth;
        canvas.height = this.naturalHeight;

        //next three lines for white background in case png has a transparent background
        var ctx = canvas.getContext("2d");
        ctx.fillStyle = "#fff"; /// set white fill style
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        canvas.getContext("2d").drawImage(this, 0, 0);

        resolve(canvas.toDataURL("image/jpeg"));
      };

      image.src = url;
    });
  };

  const pdf = async () => {
    let vertical = 0;
    let horizontal = 0;
    var doc = new jsPDF({
      orientation: "p",
      unit: "mm",
      format: "letter"
    });
    doc.setFontSize(16);
    doc.setFontType("bold");
    doc.text(notaria.nombre_notaria, 20, 25);
    doc.setFontSize(10);
    vertical = 30;
    horizontal = 20;
    doc.setFontType("normal");
    doc.text(notaria.notario, horizontal, vertical);
    doc.text("Nit. " + notaria.identificacion, horizontal, vertical + 5);
    doc.text(notaria.direccion, horizontal, vertical + 10);
    doc.text(notaria.telefono, horizontal, vertical + 15);
    doc.text(notaria.regimen, horizontal, vertical + 20);
    doc.line(horizontal, vertical + 23, 200, vertical + 23);
    doc.line(horizontal, vertical + 24, 200, vertical + 24);

    var urlLogo = await getDataUri(logo);
    doc.addImage(urlLogo, "JPEG", 130, 20, 60, 25);

    vertical = 60;
    doc.setFontSize(16);
    doc.setFontType("bold");
    doc.text("Cotización Previa", 100, vertical);
    doc.setFontSize(12);
    doc.setFontType("normal");
    doc.text("Acto:", horizontal + 10, vertical + 10);
    doc.text(nombreActo, horizontal + 23, vertical + 10);
    var f = new Date();
    doc.text(
      "Fecha: " +
        f.getDate() +
        "/" +
        (f.getMonth() + 1) +
        "/" +
        f.getFullYear(),
      horizontal + 130,
      vertical + 10
    );
    if (showCuantía) {
      doc.text("Cuantía:", horizontal + 35, vertical + 20);
      doc.text("$" + formatear(valorCuantia), horizontal + 55, vertical + 20);
    }
    if (showHipoteca) {
      doc.text("Hipoteca:", horizontal + 95, vertical + 20);
      doc.text("$" + formatear(valorHipoteca), horizontal + 115, vertical + 20);
    }

    doc.setFontType("bold");
    vertical = 90;
    horizontal = 10;
    doc.text("Servicio", horizontal + 20, vertical);
    doc.text("Cantidad", horizontal + 80, vertical, "center");
    doc.text("Precio. Uni", horizontal + 110, vertical);
    doc.text("Total", horizontal + 156, vertical);

    doc.setFontType("normal");
    vertical = 100;
    horizontal = 30;
    {
      servicios.map((item) => {
        doc.text(item.servicio_name, horizontal, vertical);
        doc.text(String(item.cant_predet), horizontal + 60, vertical);
        doc.text(
          "$" + formatear(item.valor_unit),
          horizontal + 110,
          vertical,
          "right"
        );
        doc.text(
          "$" + formatear(item.valor_unit * item.cant_predet),
          horizontal + 150,
          vertical,
          "right"
        );
        vertical += 5;
      });
    }

    vertical += 10;
    doc.text("", horizontal, vertical);
    doc.text("", horizontal + 60, vertical);
    doc.text("Iva:", horizontal + 110, vertical, "right");
    doc.text("$" + formatear(tIva), horizontal + 150, vertical, "right");
    vertical += 5;
    doc.text("", horizontal, vertical);
    doc.text("", horizontal + 60, vertical);
    doc.text("Super:", horizontal + 110, vertical, "right");
    doc.text("$" + formatear(tSuper), horizontal + 150, vertical, "right");
    vertical += 5;
    doc.text("", horizontal, vertical);
    doc.text("", horizontal + 60, vertical);
    doc.text("Fondo:", horizontal + 110, vertical, "right");
    doc.text("$" + formatear(tFondo), horizontal + 150, vertical, "right");
    vertical += 5;
    doc.text("", horizontal, vertical);
    doc.text("", horizontal + 60, vertical);
    if (checkedRete) {
      doc.text("Retención:", horizontal + 110, vertical, "right");
      doc.text("$" + formatear(tRete), horizontal + 150, vertical, "right");
    }

    vertical += 5;
    doc.setFontType("bold");
    doc.text("", horizontal, vertical);
    doc.text("", horizontal + 60, vertical);
    doc.text("Gran Total:", horizontal + 110, vertical, "right");
    doc.text("$" + formatear(tCotizacion), horizontal + 150, vertical, "right");
    doc.setFontType("normal");

    vertical = 200;
    doc.line(20, vertical, 200, vertical);
    doc.setFontSize(10);
    doc.text("Generado por Sinfony", 85, vertical + 5);
    doc.save("Cotización.pdf");
  };

  return (
    <Fragment>
      <div className="header-2">
        <div
          className="page-header"
          style={{
            backgroundImage:
              "url(" + require("assets/img/sections/bga1.jpg") + ")"
          }}
        >
          <div className="filter" />
          <div className="content-center">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2
                    className="title"
                    style={{fontFamily: "'Archivo Black', sans-serif"}}
                  >
                    Cotizador en Linea
                  </h2>
                  <br/>
                </Col>
                <Col className="ml-auto mr-auto" md="10">
                  <Card className="card-raised card-form-horizontal no-transition">
                    <CardBody>
                      <Form action="" method="">
                        <Row>
                          <Col md="6">
                            <h4 style={{marginTop:'4px',  fontWeight:500}}>
                              Acto a cotizar:
                            </h4>
                          </Col>
                          <Col md="5">
                            <FormGroup style={{fontFamily: "'Raleway', sans-serif"}}>
                              <Select
                                className="react-select react-select-success font-weight-normal text-dark text-uppercase"
                                classNamePrefix="react-select"
                                value={tipoEscritura}
                                onChange={selectTipoEscritura}
                                options={selectOptions}
                                placeholder="ELIJA..."
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <div>
        <Row className="items-row ml-auto mr-auto">
          {showDerechos && (
            <Fragment>
              <Col
                md="6"
                sm="6"
                className="ml-auto mr-auto"
                style={{marginTop:'-45px', border:'0'}}
              >
                <Card
                  className="card-profile pt-3"
                  style={{backgroundColor:'#FFFFFF'}}
                >
                  <CardBody>
                    {showCuantía && (
                      <Fragment>
                        <InputGroup>
                          <Input
                            onChange={e => setValorCuantia(e.target.value)}
                            placeholder="Cuantía"
                            type="number"
                            value={valorCuantia}
                          />
                          <Input>
                            <InputGroupText>
                              <i className="fa fa-pencil-square-o fa-lg" />
                            </InputGroupText>
                          </Input>
                        </InputGroup>
                        <label
                          className="pt-3 font-weight-normal"
                          style={{fontSize:'17px'}}
                        >
                          <b>Valor Cuantía: $</b> {formatear(valorCuantia)}
                        </label>
                      </Fragment>
                    )}
                    {showHipoteca && (
                      <Fragment>
                        <InputGroup>
                          {/* Since the css properties cannot check the previous sibling of an element and for the design consistency we recommend to use the "input-group-addon" after the "form-control" like in this example */}
                          <Input
                            placeholder="Hipoteca"
                            type="number"
                            onChange={e =>
                              setValorHipoteca(e.currentTarget.value)
                            }
                          />
                          <Input>
                            <InputGroupText>
                              <i className="fa fa-pencil-square-o fa-lg" />
                            </InputGroupText>
                          </Input>
                        </InputGroup>
                        <label
                          className="pt-3 font-weight-normal"
                          style={{fontSize:'17px'}}
                        >
                          <b>Valor Hipoteca: $</b> {formatear(valorHipoteca)}
                        </label>
                      </Fragment>
                    )}
                    <CardFooter className="text-center py-3">
                      {(showCuantía || showHipoteca || showStatico) && (
                        <Button
                          className="btn-icon btn-round"
                          color="warning"
                          type="button"
                          onClick={cotizar}
                        >
                          Cotizar
                        </Button>
                      )}
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
            </Fragment>
          )}
        </Row>
        <br />
        <Row className="items-row" style={{padding: '61px 0px'}}>
          <Col md="8" className="ml-auto mr-auto">
            <Card style={{backgroundColor:'#f2f2f2'}}>
              <CardBody>
                <div className="clearfix" />
                {showStatico && (
                  <Col className="ml-auto mr-auto" md="12">
                    <Table responsive className="table table-hover">
                      <thead className="thead-dark">
                        <tr>
                          <th className="text-center font-weight-normal">
                            Servicio
                          </th>
                          <th
                            className="font-weight-normal"
                            style={{paddingLeft:'113px'}}
                          >
                            Cantidad
                          </th>
                          <th className="font-weight-normal">
                            Precio Unitario
                          </th>
                          <th className="font-weight-normal">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {servicios.map(item => (
                          <tr key={uuidv4()}>
                            <td className="font-weight-normal">{item.servicio_name} </td>
                            <td className="font-weight-normal">
                              <InputGroup style={{maxWidth:'27%', margin:'auto'}}>
                                <Input
                                  id={item.id.toString()}
                                  placeholder="Hipoteca"
                                  type="number"
                                  disabled={service_disabled.includes(
                                    item.id
                                  )}
                                  defaultValue={item.cant_predet}
                                  onChange={e =>
                                    updateCantidad(
                                      e.target.id,
                                      e.target.value
                                    )
                                  }
                                />
                              </InputGroup>
                            </td>
                            <td className="font-weight-normal">{"$" + formatear(item.valor_unit)}</td>
                            <td className="font-weight-normal">
                              {"$" +
                                formatear(
                                  item.valor_unit * item.cant_predet
                              )}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td></td>
                          <td></td>
                          <td className="font-weight-normal">IVA</td>
                          <td>{"$" + formatear(tIva)}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td className="font-weight-normal">Super</td>
                          <td className="font-weight-normal">{"$" + formatear(tSuper)}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td className="font-weight-normal">Fondo</td>
                          <td className="font-weight-normal">{"$" + formatear(tFondo)}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>Retención <b>SI</b> para persona Natural y <b>NO</b> para persona Jurídica*</td>
                          <td className="font-weight-normal">
                            <div className="custom-control custom-switch">
                              <input
                                checked={checkedRete}
                                type="checkbox"
                                className="custom-control-input"
                                id="customSwitch1"
                                onChange={event => CheckedRetencion(event.target.checked)}
                              ></input>
                              <label
                                className="custom-control-label"
                                htmlFor="customSwitch1"
                              >Retención
                              </label>
                            </div>
                          </td>
                          <td className="font-weight-normal">
                            {checkedRete && "$" + formatear(tRete)}
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td className="font-weight-normal">Total Cotización</td>
                          <td className="font-weight-normal">{"$" + formatear(tCotizacion)}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            <Button onClick={pdf}>Imprimir</Button>
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                )}
                {!showStatico && (
                  <div>
                    <h4
                      className="author pull-left"
                      style={{fontFamily: "'Raleway', sans-serif", fontWeight:500}}
                    >
                      Cotizador
                    </h4>
                    <span className="category-social  pull-right">
                      <i
                        className="fa fa-calculator fa-4x"
                        style={{fontSize:'55px'}}
                      ></i>
                    </span>
                    <div className="clearfix"></div>
                    <hr style={{backgroundColor:'#dfdfdf', height:'4px'}}></hr>
                    <p
                      className="card-description font-weight-normal"
                      style={{fontSize:'18px', color:'black', fontFamily: "'Raleway', sans-serif", fontWeight:400}}
                    >
                      Total: ${formatear(tCotizacion)}
                    </p>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Cotizador;
