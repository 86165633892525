import React from "react";

// reactstrap components
import {

  Container
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";


function Normativa() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <ColorNavbar />
      <div className="main" style={{ marginTop: "8em" }}>
        <div className="section">
          <Container>
            <h3 className="title-uppercase text-center" style={{fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif"}}>
            Normativa que regula la Entidad
            </h3>
            <ul className="mt-4" style={{fontFamily: "'Noto Sans SC', sans-serif"}}>
              <li>
                <p>
                  <a style={{textDecoration:"none",color:"black",fontWeight:500 }} href="http://www.secretariasenado.gov.co/senado/basedoc/decreto_0960_1970.html" target="_blank" rel="noopener noreferrer">Decreto 960 de 1970</a>
                </p>
              </li>
              <li>
                <p>
                  <a style={{textDecoration:"none",color:"black",fontWeight:500 }} href="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=74174" target="_blank" rel="noopener noreferrer">Decreto 1069 de 2015 Sector Justicia y del Derecho</a>
                </p>
              </li>
              <li>
                <p>
                  <a style={{textDecoration:"none",color:"black",fontWeight:500 }} href="http://www.secretariasenado.gov.co/senado/basedoc/decreto_1260_1970.html#:~:text=El%20estado%20civil%20de%20una,asignaci%C3%B3n%20corresponde%20a%20la%20ley." target="_blank" rel="noopener noreferrer">Decreto 1260 de 1970</a>
                </p>
              </li>
              <li>
                <p>
                  <a style={{textDecoration:"none",color:"black",fontWeight:500 }} href="http://www.suin-juriscol.gov.co/viewDocument.asp?id=1478913" target="_blank" rel="noopener noreferrer">Decreto 2668 de 1988</a>
                </p>
              </li>
              <li>
                <p>
                  <a style={{textDecoration:"none",color:"black",fontWeight:500 }} href="http://www.secretariasenado.gov.co/senado/basedoc/ley_1564_2012.html" target="_blank" rel="noopener noreferrer">Ley 1564 de 2012</a>
                </p>
              </li>
              <li>
                <p>
                  <a style={{textDecoration:"none",color:"black",fontWeight:500 }} href="http://www.secretariasenado.gov.co/senado/basedoc/ley_0588_2000.html" target="_blank" rel="noopener noreferrer">Ley 588 de 2000</a>
                </p>
              </li>
              <li>
                <p>
                  <a style={{textDecoration:"none",color:"black",fontWeight:500 }} href="http://www.suin-juriscol.gov.co/viewDocument.asp?id=30019870" target="_blank" rel="noopener noreferrer">Decreto 1069 de 2015</a>
                </p>
              </li>
              <li>
                <p>
                  <a style={{textDecoration:"none",color:"black",fontWeight:500 }} href="http://www.secretariasenado.gov.co/senado/basedoc/ley_1996_2019.html" target="_blank" rel="noopener noreferrer">Ley 1996 de 2019</a>
                </p>
              </li>
              <li>
                <p>
                  <a style={{textDecoration:"none",color:"black",fontWeight:500 }} href="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=61836" target="_blank" rel="noopener noreferrer">Decreto 1000 de 2015</a>
                </p>
              </li>
              <li className="list-group-item">
                <a
                  href="https://www.suin-juriscol.gov.co/"
                  target="_black"
                  style={{
                  fontWeight: 500,
                  fontFamily: "'Noto Sans SC', sans-serif"
                  }}
                >
                  Enlace a Sistema Único de Información Normativa - SUIN.
                </a>
              </li>
            </ul>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Normativa;
