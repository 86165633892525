import React from "react";

// reactstrap components
import {  Modal,
          Button

} from "reactstrap";

// core components

function Funciones(props) {

  return (
    <>
      <Modal isOpen={props.modal} toggle={props.setModal} className="modal-lg modal-dialog modal-dialog-centered">
      <div className="modal-header">
        <button
            className="close"
            type="button"
            onClick={props.setModal}
        >
            <span>×</span>
        </button>
        <h5
            className="modal-title text-center"
            id="exampleModalLabel"
            style={{ fontWeight: 500,fontFamily: "'Noto Sans SC', sans-serif" }}
        >
            Funciones y Deberes
        </h5>
        </div>
        <div className="modal-body">
            <ul>
                <li>
                    <p>
                        <a
                        style={{
                            textDecoration: "none",
                            color: "black",
                            fontWeight: 500,
                            fontFamily: "'Noto Sans SC', sans-serif"
                        }}
                        href="http://www.secretariasenado.gov.co/senado/basedoc/decreto_0960_1970.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        >
                        <u>DECRETO 960 DE 1970</u>
                        </a>
                    </p>
                </li>
                <li>
                    <p>
                        <a
                        style={{
                            textDecoration: "none",
                            color: "black",
                            fontWeight: 500,
                            fontFamily: "'Noto Sans SC', sans-serif"
                        }}
                        href="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=74174"
                        target="_blank"
                        rel="noopener noreferrer"
                        >
                        <u>Decreto 1069 de 2015 Sector Justicia y
                        del Derecho</u>
                        </a>
                    </p>
                </li>
            </ul>
        </div>
        <div className="modal-footer">
            <div className="text-center">
                <Button
                className="btn-link"
                color="danger"
                type="button"
                onClick={props.setModal}
                >
                Cerrar
                </Button>
            </div>
        </div>
        </Modal>
    </>
  );
}

export default Funciones;
