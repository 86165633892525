import React from "react";

// reactstrap components
import {

  Container
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";

function MapaProcesos() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <ColorNavbar />
      <div className="main" style={{ marginTop: "8em" }}>
        <div className="section">
          <Container>
            <h3 className="title-uppercase text-center" style={{fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif"}}>
              Mapa de Procesos
            </h3>
            <center className="mt-5">
              <a href={require("assets/pdf/MAPA DE PROCESOS (1).pdf")} style={{textDecoration: "none !important"}} rel="noopener noreferrer" target="_blank">
                <img
                  alt="Imagen con referencia Mapa Procesos"
                  width="80px"
                  src={require("assets/img/sections/pdf.jpg")}
                ></img>
                <h6 className="my-3">Mapa Procesos</h6>
              </a>
            </center>
          </Container>
        </div>
      </div>
    </>
  );
}

export default MapaProcesos;
