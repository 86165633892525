import React from "react";

// reactstrap components
import {
  Container
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";


function InfoTramites() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <ColorNavbar />
      {/* <BlogPostHeader /> */}
      <div className="main" style={{ marginTop: "8em" }}>
        <div className="section">
          <Container>
            <h3 className="title-uppercase text-center" style={{fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif"}}>
              Tramites
            </h3>
            <p className="my-4" style={{ textAlign: "justify", fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif" }}>
            Los trámites que adelantan las Notarías son los siguientes:
            </p>
            <ol style={{ textAlign: "justify", fontWeight:500,fontFamily: "'Noto Sans SC', sans-serif" }}>
              <li>
                <p>Recibir, extender y autorizar las declaraciones que conforme a las Leyes requieran escritura pública y aquellas a las cuales los interesados quieran revestir de esta solemnidad.</p>
              </li>
              <li>
                <p>Autorizar el reconocimiento espontáneo de documentos privados</p>
              </li>
              <li>
                <p>Dar testimonio de la autenticidad de firmas de funcionarios o particulares y de
                  otros Notarios que las tengan registradas ante ellos.</p>
              </li>
              <li>
                <p>Dar fe de la correspondencia o identidad que exista entre un documento que tenga a la vista y su copia mecánica o literal.</p>
              </li>
              <li>
                <p>Acreditar la existencia de las personas naturales y expedir la correspondiente fe de vida</p>
              </li>
              <li>
                <p>Recibir y guardar dentro del protocolo los documentos o actuaciones que la Ley o el Juez ordenen protocolizar o que los interesados quieran proteger de esta manera.</p>
              </li>
              <li>
                <p>Expedir copias o certificaciones según el caso, de los documentos que reposen en sus archivos. </p>
              </li>
              <li>
                <p>Dar testimonio escrito con fines jurídico - probatorios de los hechos percibidos por ellos dentro del ejercicio de sus funciones y de que no haya quedado dato formal en sus archivos.</p>
              </li>
              <li>
                <p>Intervenir en el otorgamiento, extensión y autorización de los testamentos solemnes que conforme a la Ley civil deban otorgarse ante ellos.</p>
              </li>
              <li>
                <p>Practicar apertura y publicación de los testamentos cerrados. (...)</p>
              </li>
              <li>
                <p>Llevar el registro del estado civil de las personas en los casos, por los sistemas y con las formalidades prescritos en la Ley.</p>
              </li>
              <li>
                <p>Las demás funciones que les señalen las Leyes. </p>
              </li>
            </ol>
            <p style={{fontFamily: "'Noto Sans SC', sans-serif"}}>Procedimientos y protocolos de atención: </p>
            <p style={{fontFamily: "'Noto Sans SC', sans-serif"}}>
              <a style={{textDecoration:"none",fontFamily: "'Noto Sans SC', sans-serif"}} rel="noopener noreferrer" href="https://www.ucnc.com.co/sitio/wp-content/uploads/2020/09/2020-08-21-PROTOCOLO-DE-BIOSEGURIDAD-PARA-NOTARIAS-UCNC-Version-Final-Anexo-SNR.pdf" target="_blank">https://www.ucnc.com.co/sitio/wp-content/uploads/2020/09/2020-08-21-PROTOCOLO-DE-BIOSEGURIDAD-PARA-NOTARIAS-UCNC-Version-Final-Anexo-SNR.pdf</a>
            </p>
            <h6 className="my-4" style={{fontFamily: "'Noto Sans SC', sans-serif"}}>Formatos y Formularios</h6>
            <center className="mt-5">
              <a href={require("assets/pdf/fSALIDA DEL PAIS ELLA A EL MINUTA.pdf")} style={{textDecoration: "none !important"}} target="_blank" rel="noopener noreferrer">
                <img
                  alt="Imagen con referencia hacia un icono para descargar un archivo en PDF"
                  width="80px"
                  src={require("assets/img/sections/pdf.jpg")}
                ></img>
                <h6 className="my-3">Salida del Pais</h6>
              </a>
            </center>
            <center className="mt-5">
              <a href={require("assets/pdf/fPODER  EL A ELLA  MINUTA.pdf")} style={{textDecoration: "none !important"}} target="_blank" rel="noopener noreferrer">
                <img
                  alt="Imagen con referencia hacia un icono para descargar un archivo en PDF"
                  width="80px"
                  src={require("assets/img/sections/pdf.jpg")}
                ></img>
                <h6 className="my-3">PODER  EL A ELLA  MINUTA</h6>
              </a>
            </center>
          </Container>
        </div>
      </div>
    </>
  );
}

export default InfoTramites;
