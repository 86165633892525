import React from "react";
// reactstrap components
import { Container,
  Row,
  Col,
  Card,
  Carousel,
  CarouselItem,
  CarouselIndicators } from "reactstrap";

// core components
const carouselItems = [
  {
    src: require("assets/img/sections/bogota12.jpg"),
    altText: "Ver cotizador",
    caption: "Somewhere",
    texto:
      "En este módulo podrá realizar las cotizaciones previas a sus escrituras de: Compraventa, Compraventa e hipoteca con particulares y/o entidades financieras, Hipotecas, cancelaciones de hipoteca, matrimonios y sucesiones, entre otras",
    boton: "Ver Cotizador",
    titulo: 'Cotizador en Linea',
    ruta: "/cotizador" 
  },
  {
    src: require("assets/img/sections/bogota12.jpg"),
    altText: "Somewhere else",
    caption: "Somewhere else",
    texto: "This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.",
    boton: "Ver Más",
    titulo: 'Noticia 02',
    ruta: "#" 
  },
  {
    src: require("assets/img/sections/bogota12.jpg"),
    altText: "Here it is",
    caption: "Here it is",
    texto: "This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.",
    boton: "Ver Más",
    titulo: 'Noticia 03',
    ruta: "#" 
  }
];
function SectionCards() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === carouselItems.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? carouselItems.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div
        className="section section-components cd-section section-dark"
        style={{ paddingBottom: "40px" }}
        id="aplicativos"
      >
        <Row>
          <Col className="col-lg-6 offset-lg-1" md="12">
            <Card className="card-raised page-carousel">
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                <CarouselIndicators
                  items={carouselItems}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {carouselItems.map(item => {
                  return (
                    <CarouselItem
                      onExiting={onExiting}
                      onExited={onExited}
                    >
                      {/* <img src={item.src} alt={item.altText} /> */}
                      <div className="jumbotron">
                        <h2
                          className="text-center"
                          style={{
                            fontFamily: " 'Nanum Gothic', sans-serif"
                          }}
                        >
                          {item.titulo}
                        </h2>
                        <p
                          className="lead text-center"
                          style={{ padding: "1em 2em" }}
                        >
                          {item.texto}
                        </p>
                        <hr className=""></hr>
                        <div className="text-center" style={{marginRight:'100px'}}>
                          {/* <button
                          type="button"
                            className="btn btn-round"
                            style={{
                              backgroundColor: "#379C11",
                              color: "white",
                              borderColor: "#379C11"
                            }}
                            href="https://getbootstrap.com/docs/4.0/components/jumbotron/"
                          >
                            {item.boton}
                          </button> */}
                          <a className="btn btn-round" style={{
                              backgroundColor: "#379C11",
                              color: "white",
                              borderColor: "#379C11"
                          }}href={item.ruta} role="button">{item.boton}</a>
                        </div>
                      </div>
                      {/* <p>{item.texto}</p> */}
                      {/* <CarouselCaption
                          style={{color:'black'}}
                          captionText={item.caption}
                          
                          captionHeader=""
                        /> */}
                      {/* <div className="carousel-caption d-none d-md-block">
                        <h5>...</h5>
                        <p style={{ color: "black", fontWeight: 500 }}>
                          {item.caption}
                        </p>
                      </div> */}
                    </CarouselItem>
                  );
                })}
                <a
                  className="left carousel-control carousel-control-prev"
                  data-slide="prev"
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    previous();
                  }}
                  role="button"
                >
                  <span className="fa fa-angle-left" />
                  <span className="sr-only">Previous</span>
                </a>
                <a
                  className="right carousel-control carousel-control-next"
                  data-slide="next"
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    next();
                  }}
                  role="button"
                >
                  <span className="fa fa-angle-right" />
                  <span className="sr-only">Next</span>
                </a>
              </Carousel>
            </Card>
          </Col>
          <Col lg="4" md="10">
            <Container
              className="basic-container"
              style={{ marginLeft: "7em" }}
            >
              <span className="" id="noticias">
                <h1
                  className="display-2 text-white font-weight-bolder"
                  style={{
                    margin: "104px 0px 0px",
                    fontFamily: " 'Nanum Gothic', sans-serif"
                  }}
                >
                  Noticias
                </h1>
              </span>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SectionCards;
