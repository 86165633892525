import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
  FormGroup
} from "reactstrap";

// core components
// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import Logos from "./Logos.js"

function ContactUs() {
  const [checkedEmail, setCheckedEmail] = React.useState(false);

  const check = e => {
    let m = document.getElementById("contact-form");
    let x = document.getElementById("check").checked;
    setCheckedEmail(x);
    m.reset();
    let p = document.getElementById("mostrar");
    console.log(x);
    p.style.display = "block";
    setTimeout(() => {
      p.style.display = "none";
      setCheckedEmail(false);
    }, 2000);
  };

  return (
    <>
      <ColorNavbar />
      <div className="main" id="contact-us">
        <div className="section section-gray">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Para mayor información</h2>
                <p className="font-weight-normal mt-2">
                  Visite nuestras oficinas o comuníquese con nuestras líneas de
                  atención al cliente para recibir información adicional.
                </p>
              </Col>
            </Row>
            <Row>
              <Col 
                className="ml-auto mr-auto text-center" md="6"> 
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center mt-5" md="6">
                <Form className="contact" id="contact-form">
                  <Row className="my-2">
                    <Col md="6">
                      <Input placeholder="Primer Nombre" type="text" />
                    </Col>
                    <Col md="6">
                      <Input placeholder="Primer Apellido" type="text" />
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col>
                      <Input placeholder="Correo Electronico" type="text" />
                    </Col>
                  </Row>
                  <Input placeholder="Mensaje" rows="7" type="textarea" />
                  <Row className="my-3">
                    <Col sm="5">
                      <FormGroup check>
                        <Label check>
                          <Input
                            id="check"
                            defaultValue=""
                            type="checkbox"
                          />
                          No soy un robot <span className="form-check-sign" />
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col className="ml-auto mr-auto" md="7">
                      <Button
                        type="button"
                        className="btn-round"
                        style={{
                          borderColor: "#64bb46",
                          backgroundColor: "#64bb46",
                          color: "white"
                        }}
                        onClick={check}
                      >
                        Enviar Mensaje
                      </Button>
                    </Col>
                  </Row>
                  <Row id="mostrar" className="my-3 ">
                    {checkedEmail && (
                      <Col md="6">
                        <div
                          className="alert alert-danger font-weight-normal text-center"
                          role="alert"
                        >
                          Enviado!
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Row style={{marginTop:'-22px'}} >
                    <Col md="8" className="text-center ml-auto mr-auto mb-5">
                      <h4 className="mb-4">Atención al Público</h4>
                      <Button
                        className="btn-rotate btn-block btn-round mr-1 "
                        color="default"
                        to="/LoginPage" tag={Link}
                      >
                        <i className="fa fa-hand-pointer-o" aria-hidden="true"></i>
                        PQRS
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col className="ml-auto" md="5">
                <CardBody
                  className="py-4"
                  style={{ padding: "24px", marginLeft: "-16px", backgroundColor:"white", borderRadius:"20px", marginBottom:"20px"}}
                >
                  <div className="info info-horizontal">
                    <div className="icon icon-warning">
                      <i className="nc-icon nc-pin-3" />
                    </div>
                    <div className="description">
                      <h5 className="info-title font-weight-normal">
                        Estamos ubicados
                      </h5>
                      <p
                        className="font-weight-normal"
                        style={{ color: "#75706F" }}
                      >
                        Cra 21 N° 22 - 30 Bucaramanga, Colombia <br />
                        Horarios de atención: <br />
                        Lunes a jueves 7:00am - 1:00 y de 2:00 - 5:00pm <br />
                        Viernes: 7:00am - 12:00 y de 2:00 - 5:00pm <br />
                        Sábados de turno: 8:00am a 12:00pm <br />
                      </p>
                      <p
                        className="font-weight-normal"
                        style={{ color: "#75706F" }}
                      >
                        Sábados de turno:   
                      </p>
                      <p
                        className="font-weight-normal"
                        style={{ color: "#75706F" }}
                      >
                        Diciembre 7 <br></br>                         
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-danger">
                      <i className="nc-icon nc-badge" />
                    </div>
                    <div className="description">
                      <h5 className="info-title font-weight-normal">
                        Contacto
                      </h5>
                      <p
                        className="font-weight-normal"
                        style={{ color: "#75706F" }}
                      >
                        PBX -- FAX: 6421111 <br />
                        CEL : 317 513 0082
                        <br />
                      </p>
                      <p
                        className="font-weight-normal"
                        style={{ color: "#75706F" }}
                      >
                        Email: <br />
                        <span>decimabucaramanga@supernotariado.gov.co</span>
                        <br />
                        <span>notariadecimabucaramanga@gmail.com</span>
                      </p>
                    </div>
                    <div>
                      <Row className="my-4">
                        <Col md="6" sm="4">
                          <div className="">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://www.waze.com/es/livemap/directions/colombia/santander/bucaramanga/notaria-10?place=ChIJkYU5X3sVaI4Ra6LpuYb9BTI"
                            >
                              <img
                                alt="Logo de la aplicación Wazw"
                                src={require("assets/img/sections/waze-logo.png")}
                                className=" waze"
                              ></img>
                            </a>
                          </div>
                        </Col>
                        <Col md="6" sm="4">
                          <div className="">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://www.google.com/maps/dir/?api=1&destination=Notaria+10,+Cra.+21+%2322-30,+Bucaramanga,+Santander"
                            >
                              <img
                                alt="Logo de Google Maps"
                                src={require("assets/img/sections/maps.png")}
                                className="maps"
                              ></img>
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Col>
            </Row>
            <Row>
              <Col className="col-sm-12" md="12" style={{ marginLeft: "-3vh" }}>
                <div className="" id="contactUsMap2">
                  <iframe
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3958.9957622793745!2d-73.12639868573154!3d7.126485317837901!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e68157b5f398591%3A0x3205fd86b9e9a26b!2sNotaria%2010!5e0!3m2!1ses!2sco!4v1595441843968!5m2!1ses!2sco"
                    width="1150"
                    height="200"
                    frameBorder="0"
                    style={{ border: 0 }}
                    aria-hidden="false"
                    tabIndex="0"
                  ></iframe>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Logos/>
    </>
  );
}

export default ContactUs;
